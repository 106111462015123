import { Tooltip } from 'src/components/mui-components/Tooltip';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './TimeTrackerTooltipButton.module.scss';
import { TimeTrackerTooltipButtonProps } from '../../TimeTrackerButton.d';

export const TimeTrackerTooltipButton = ({
  handleChangeActive,
  isActive,
  elementProps,
  timespan,
}: TimeTrackerTooltipButtonProps) => {
  const { t } = useTranslation('header');
  return (
    <Tooltip title={t('TimeTrackerOpenButton')} placement="bottom-start">
      <button
        type="button"
        className={cx(styles.TimeTrackerButton, {
          [styles.TimeTrackerButton____isActive]: !!timespan,
        })}
        aria-label={t('TimeTrackerOpenButton')}
        onClick={handleChangeActive}
        aria-controls="time-tracker"
        aria-expanded={isActive}
        data-automation-id="TimeTrackerButton"
        {...elementProps}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.TimeTrackerButton__icon}
          data-automation-id="TimeTrackerButtonIcon"
        >
          <path
            fill="currentColor"
            d="M15,1h-6V3h6V1Zm4.03,6.39l1.42-1.42c-.43-.51-.9-.99-1.41-1.41l-1.42,1.42c-1.55-1.24-3.5-1.98-5.62-1.98C7.03,4,3,8.03,3,13s4.02,9,9,9,9-4.03,9-9c0-2.12-.74-4.07-1.97-5.61Zm-7.03,12.61c-3.87,0-7-3.13-7-7s3.13-7,7-7,7,3.13,7,7-3.13,7-7,7Z"
          />
          <path
            className={styles.TimeTrackerButton__heart}
            d="M7.9,11.55c0,.57,.23,1.07,.6,1.45l3.5,3.5,3.5-3.5c.37-.37,.6-.89,.6-1.45,0-1.13-.92-2.05-2.05-2.05-.57,0-1.08,.23-1.45,.6l-.6,.6-.6-.59c-.37-.38-.89-.61-1.45-.61-1.13,0-2.05,.92-2.05,2.05Z"
          />
        </svg>
        {!!timespan && (
          <span className={styles.TimeTrackerButton__time} data-automation-id="TimeTrackerText">
            {timespan.hour.toLocaleString('eb-US', { minimumIntegerDigits: 2 })}
            <span className={styles.TimeTrackerButton__colon}>:</span>
            {timespan.min.toLocaleString('eb-US', { minimumIntegerDigits: 2 })}
          </span>
        )}
      </button>
    </Tooltip>
  );
};

import { useEffect, useState } from 'react';
import Error from 'src/components/MilestoneFeed/components/Error/Error';
import Loading from 'src/components/MilestoneFeed/components/Loading/Loading';
import ReportingWidgetCard from 'src/components/ReportingWidgetCard/ReportingWidgetCard';
import { DataGrid, GridPaginationModel } from 'src/components/mui-components';

interface IReportingWidgetDataGrid {
  data: {}[];
  columnData: {}[];
  hasPagination: boolean;
  paginationModel?: GridPaginationModel;
  setPaginationModel?: (model: GridPaginationModel) => void;
  rowCount?: number;
  disableColumnTopBorder?: boolean;
  disableCellBorder?: boolean;
  isLoading: boolean;
}

interface IReportingWidgetWithTable {
  name: string;
  title: string;
  data: {}[];
  columnData: {}[];
  infoIconText: string;
  hasPagination?: boolean;
  paginationModel?: GridPaginationModel;
  setPaginationModel?: (model: GridPaginationModel) => void;
  rowCount?: number;
  disableColumnTopBorder?: boolean;
  disableCellBorder?: boolean;
  isEmpty: boolean;
  isLoading: boolean;
  isError: boolean;
}

const rowDataParser = (data: any) => {
  // DRO: id is MANDATORY for datagrid.
  const rowResult = data.map((item: any, index: any) => ({ id: index, ...item }));
  return rowResult;
};

const columnDataParser = (data: any) => {
  const colResult = data.map((item: any) => ({
    field: item.key,
    headerName: item.headerName,
    sortable: false,
    disableReorder: true,
    ...item,
  }));

  return colResult;
};

const ReportDataGrid = ({
  data,
  columnData,
  hasPagination,
  isLoading,
  paginationModel,
  setPaginationModel,
  rowCount,
  disableColumnTopBorder = true,
  disableCellBorder = true,
}: IReportingWidgetDataGrid) => {
  const PAGE_SIZE_OPTIONS = [15, 25, 50, 100];
  const [totalRecord, setTotalRecord] = useState(rowCount || 0);

  useEffect(() => {
    setTotalRecord((prevTotalRecord) => (rowCount !== undefined ? rowCount : prevTotalRecord));
  }, [rowCount, setTotalRecord]);

  const rows = rowDataParser(data);
  const cols = columnDataParser(columnData);

  return hasPagination ? (
    <DataGrid
      sx={{ height: '470px' }}
      rows={rows}
      columns={cols}
      pagination
      pageSizeOptions={PAGE_SIZE_OPTIONS}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      rowCount={totalRecord}
      loading={isLoading}
      disableColumnTopBorder={disableColumnTopBorder}
      disableCellBorder={disableCellBorder}
      disableColumnMenu
      disableColumnResize
    />
  ) : (
    <DataGrid
      sx={{ height: '480px' }}
      rows={rows}
      columns={cols}
      hideFooter
      disableColumnTopBorder={disableColumnTopBorder}
      disableCellBorder={disableCellBorder}
      disableColumnMenu
      disableColumnResize
    />
  );
};

export const ReportingWidgetWithTable = ({
  name,
  title,
  data,
  columnData,
  infoIconText,
  hasPagination = false,
  paginationModel,
  setPaginationModel,
  rowCount,
  disableColumnTopBorder,
  disableCellBorder,
  isEmpty,
  isLoading,
  isError,
}: IReportingWidgetWithTable) => {
  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error />;
  }

  return (
    <ReportingWidgetCard title={title} name={name} infoIconText={infoIconText} hasAction>
      {!isEmpty && (
        <div data-automation-id={`ReportingWidgetDataGrid-${name}`}>
          <ReportDataGrid
            data={data}
            columnData={columnData}
            hasPagination={hasPagination}
            isLoading={isLoading}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            rowCount={rowCount}
            disableColumnTopBorder={disableColumnTopBorder}
            disableCellBorder={disableCellBorder}
          />
        </div>
      )}
    </ReportingWidgetCard>
  );
};

export default ReportingWidgetWithTable;
